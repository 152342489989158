<template>
    <div class="schoolManagement-order-list">
      <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
      <el-dialog title="下单失败" :visible.sync="dialogVisible" width="500px" top="25vh" :close-on-click-modal="false">
        <div style="padding: 0 0 20px 20px;">下单失败原因：{{ reason }}</div>
        <div class="dialog-footer">
        <el-button @click="dialogVisible=false">关闭</el-button>
      </div>
      </el-dialog>
    </div>
  </template>
  <script>
    export default {
      data() {
        let _this = this;
        return {
          dialogVisible: false,
          opt: {
            title: "订单列表",
            search: [
              {
                key: "name",
                label: "姓名",
              },
              {
                key: "contactMobile",
                label: "手机号码",
              },
              {
                key: "status",
                label: "下单结果",
                type: "select",
                opt: {
                  list:[{
                    label: "下单成功",
                    value: 0,
                  },{
                    label: "下单失败",
                    value: -1,
                  }]
                }
              },
            ],
            columns: [{
                label: "选卡单号",
                key: "bizId",
              },
              {
                label: "姓名",
                key: "name",
              },
              {
                label: "手机号码",
                key: "contactMobile",
              },
              {
                label: "号码归属",
                key: "ownershipName",
              },
              {
                label: "选取号码",
                key: "mobile",
              }, 
              {
                label: "收货地址",
                key: "receiverAddress",
              },
              {
                label: "下单时间",
                key: "createTime",
              },
              {
                label: "下单结果",
                key: "stateStr",
                type: "actionStatus",
                opt: {
                  ativeStatus: "下单失败",
                  on(row) {
                    _this.dialogVisible = true;
                    _this.reason = row.reason;
                  }
                }
              },
            ],
          },
          reason:'',
          dialogVisible: false,
        };
      },
      created() {
        console.log("order-list created!!");
      },
      methods: {
        onGet(opt) {
          let dto = {
            current: opt.skip,
            pageSize: opt.limit,
            ...opt.searchForm,
          };
          this.post("landlord-service/cmcc-mobile-order/query", dto, {
            isUseResponse: true
          }).then(res => {
            if(res.data.data.data){
              res.data.data.data.forEach(item =>{
                if(item.status == 0) {
                  item.stateStr = "下单成功";
                }else{
                  item.stateStr = "下单失败";
                }
              })
            }
            opt.cb(res.data.data);
          });
        },
      }
    };
  </script>
  <style lang="scss" scoped>
    /deep/.el-table td:last-child {
      padding-right: 16px
    }
    .dialog-footer {
      text-align: center;
    }
  </style>